import { postQuestionMyLink } from '@/js/axios'

import { Toast } from 'vant' //vant-UI的提示組件

import IdentityCodeValid from '@/js/ident.js'

import dayjs from 'dayjs'

import { Base64 } from 'js-base64'

import { questionDataFour, convertData, isAgeValid } from '@/js/data'

export default {
  name: 'questionFour',
  data() {
    return {
      id: '',
      progressNum: 0,
      questionNum: 4,
      currentIndex: 0,
      username: '',
      markShowNull: false,
      markShowError: false,
      radio: '',
      result: ['a', 'b'],
      swipeHeight: 0,
      errorTip: '',
      successShow: false,
      orderId: '',
      stand: false,
      areaList: [
        { text: '江蘇省', code: '3200' },
        { text: '上海市', code: '3100' },
        { text: '北京市', code: '1100' },
        { text: '天津市', code: '1200' },
        { text: '浙江省', code: '3300' },
        { text: '廣東省', code: '4400' },
        { text: '遼寧省', code: '2100' },
        { text: '山東省', code: '3700' },
        { text: '內蒙古自治區', code: '1500' },
        { text: '重慶市', code: '5000' },
        { text: '福建省', code: '3500' },
        { text: '海南省', code: '4600' },
        { text: '湖北省', code: '4200' },
        { text: '湖南省', code: '4300' },
        { text: '陝西省', code: '6100' },
        { text: '河南省', code: '4100' },
        { text: '黑龍江省', code: '2300' },
        { text: '青海省', code: '6300' },
        { text: '雲南省', code: '5300' },
        { text: '安徽省', code: '3400' },
        { text: '四川省', code: '5100' },
        { text: '貴州省', code: '5200' },
        { text: '甘肅省', code: '6200' },
        { text: '江西省', code: '3600' },
        { text: '吉林省', code: '2200' },
        { text: '山西省', code: '1400' },
        { text: '河北省', code: '1300' },
        { text: '寧夏回族自治區', code: '6400' },
        { text: '廣西壯族自治區', code: '4500' },
        { text: '新疆維吾爾自治區', code: '6500' },
        { text: '西藏自治區', code: '5400' },
        { text: '台灣省', code: '0000' },
        { text: '香港特別行政區', code: '4401' },
      ],
      height: '',
      weight: '',
      fat: '',
      waist: '',
      hip: '',
      area: '',
      drink: '',
      smoking: '',
      catering: '',
      vegetarian: '',
      sports: '',
      sleep: '',
      diseaseList: [
        { kdis: 'K01', level: '0' },
        { kdis: 'K02', level: '0' },
        { kdis: 'K03', level: '0' },
        { kdis: 'K04', level: '0' },
        { kdis: 'K05', level: '0' },
        { kdis: 'K06', level: '0' },
        { kdis: 'K07', level: '0' },
        { kdis: 'K08', level: '0' },
        { kdis: 'K09', level: '0' },
        { kdis: 'K10', level: '0' },
        { kdis: 'K11', level: '0' },
        { kdis: 'K12', level: '0' },
        { kdis: 'K13', level: '0' },

        // 新增
        { kdis: 'K14', level: '0' },
        { kdis: 'K15', level: '0' },
        { kdis: 'K16', level: '0' },
        { kdis: 'K17', level: '0' },
        { kdis: 'K18', level: '0' },
        { kdis: 'K19', level: '0' },
      ],
      diseaseListAll: [
        { kdis: 'K01', level: '0', name: '高血壓' },
        { kdis: 'K02', level: '0', name: '高血脂' },
        { kdis: 'K03', level: '0', name: '糖尿病' },
        { kdis: 'K04', level: '0', name: '慢性肝病' },
        { kdis: 'K05', level: '0', name: '慢性腎臟病' },
        { kdis: 'K06', level: '0', name: '癌症' },
        { kdis: 'K07', level: '0', name: '肝硬化' },
        { kdis: 'K08', level: '0', name: '慢性阻塞性肺炎' },
        { kdis: 'K09', level: '0', name: '關節炎' },
        { kdis: 'K10', level: '0', name: '心臟病' },
        { kdis: 'K11', level: '0', name: '腦中風' },
        { kdis: 'K12', level: '0', name: '胃腸潰瘍' },
        { kdis: 'K13', level: '0', name: '睡眠障礙' },

        // 新增
        { kdis: 'K14', level: '0', name: '貧血' },
        { kdis: 'K15', level: '0', name: '疼痛' },
        { kdis: 'K16', level: '0', name: '哮喘' },
        { kdis: 'K17', level: '0', name: '痛風' },
        { kdis: 'K18', level: '0', name: '痔瘡' },
        { kdis: 'K19', level: '0', name: '甲狀腺疾病' },
      ],
      disease: [],
      diseaseArr: [],
      diseaseShow: false,

      noSurgery: [],
      noRelativesDisease: [],
      examineList: [
        { ne: 'FG', ve: '', ut: 'A' },
        { ne: 'HBA1C', ve: '', ut: 'A' },
        { ne: 'CHOL', ve: '', ut: 'A' },
        { ne: 'LDLC', ve: '', ut: 'A' },
        { ne: 'HDLC', ve: '', ut: 'A' },
        { ne: 'TG', ve: '', ut: 'A' },
        { ne: 'UA', ve: '', ut: 'A' },
      ],
      hgbPla: '1~25',
      fgPla: '1.5~40',
      hba1cPla: '1~25',
      cholPla: '1.2~28.5',
      ldlcPla: '0~17',
      hdlcPla: '0~7',
      tgPla: '0~110',
      albPla: '10~70',
      crePla: '0~3600',
      uaPla: '0~1200',

      isBooking: true,
      bookTime: '',
      bookTime2: '',
      pickerShow: false,
      pickerShow2: false,
      bookingArea: '',
      bookingArea2: '',
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      keyboardShow: false,
      currentTime: '',
      currentTime2: '',
      minDate: '',
      maxDate: '',
      phone: '',
      phoneCode: '',
      identCode: '',
      isYY: 0, //1：跳過預約，直接調用接口。0：填寫預約信息 默認為0

      sending: false, // 正在發送短信
      submiting: false, // 正在提交

      sex: '', //性別
      sexSel: false, //是否彈出性別選擇器
      sexList: ['男', '女'],
      birth: '', //生日
      currentBirth: new Date('1990/1/1'), //默認顯示時間
      birthSel: false, //是否彈出生日選擇器

      isMuying: false, // 是否是母嬰問卷

      toBio: false, // 未填問卷 回填
      toBioData: '',

      cities: {}, //預約地址
      columns: [],

      // 新增 從疾病列表中查找對應疾病填入
      tb02: [
        { ne: 'dis01', expg: 0, kdis: 'K01' },
        { ne: 'dis02', expg: 0, kdis: 'K02' },
        { ne: 'dis03', expg: 0, kdis: 'K03' },
        { ne: 'dis04', expg: 0, kdis: 'K07' },
        { ne: 'dis05', expg: 0, kdis: 'K05' },
        { ne: 'dis06', expg: 0, kdis: 'K14' },
        { ne: 'dis07', expg: 0, kdis: 'K15' },
        { ne: 'dis08', expg: 0, kdis: 'K13' },
        { ne: 'dis09', expg: 0, kdis: 'K16' },
        { ne: 'dis10', expg: 0, kdis: 'K11' },
        { ne: 'dis11', expg: 0, kdis: 'K10' },
        { ne: 'dis12', expg: 0, kdis: 'K06' },
        { ne: 'dis13', expg: 0, kdis: 'K08' },
        { ne: 'dis14', expg: 0, kdis: 'K12' },
        { ne: 'dis15', expg: 0, kdis: 'K17' },
        { ne: 'dis16', expg: 0, kdis: 'K09' },
        { ne: 'dis17', expg: 0, kdis: 'K18' },
        { ne: 'dis18', expg: 0, kdis: 'K19' },
      ],

      sbp: '', // 收縮壓
      dbp: '', // 舒張壓
      hasFb: false,
      considerAreaShow: false,
      considerTimeShow: false,
      showPopover: false,
      showPopoverHip: false,

      userid: '',
      noBlood: 0, //是否含有驗血
      blood_price: 0,
      hasBld: false,
      bid: '',
      savedBirth: '',
      minAge: 20,
      maxAge: 85,
    }
  },
  created() {
    this.currentIndex = 0

    let minDate = new Date(new Date().setHours(0, 0, 0, 0))
    minDate.setDate(minDate.getDate() + 4)
    //如果是前三天就-3
    minDate.getDate() //得到後三天的時間
    this.minDate = minDate
    this.currentTime = minDate
    this.currentTime2 = minDate
    let maxDate = new Date(new Date().setHours(10, 0, 0, 0))
    maxDate.setDate(maxDate.getDate() + 14)
    //如果是前三天就-3
    maxDate.getDate() //得到後三天的時間
    this.maxDate = maxDate
    var payData = sessionStorage.getItem('payData')
      ? JSON.parse(sessionStorage.getItem('payData'))
      : ''
    if (payData && payData.package == 3) {
      this.isMuying = true
      this.sex = '女'
    }
    if (
      payData &&
      payData.package == 2 &&
      payData.custom.indexOf('packageFB') > -1
    ) {
      this.hasFb = true
      this.blood_price = payData.blood_price
    } else {
      // 健康100和自由定製
      // 不填生化預約
      this.blood_price = payData.blood_price
    }
    this.toBio = true

    let questionData = localStorage.getItem('questionData')
      ? JSON.parse(localStorage.getItem('questionData'))
      : ''

    this.userid = sessionStorage.getItem('userid')
      ? sessionStorage.getItem('userid')
      : ''
    if (questionData && !this.userid) {
      this.sex =
        payData.package == 3
          ? (questionData.sex = '女')
          : questionData.sex == '1'
          ? '男'
          : '女'
      this.height = questionData.height ? questionData.height : ''
      this.weight = questionData.weight ? questionData.weight : ''
      this.fat = questionData.fat ? questionData.fat : ''
      this.waist = questionData.waist ? questionData.waist : ''
      this.hip = questionData.hip ? questionData.hip : ''
      this.sbp = questionData.sbp ? questionData.sbp : ''
      this.dbp = questionData.dbp ? questionData.dbp : ''
      if (Array.isArray(questionData.examine)) {
        this.examineList = this.examineList.map((item) => {
          const arr = questionData.examine.filter(
            (qItem) => qItem.ne == item.ne
          )
          if (arr.length > 0) {
            item = arr[0]
          }
          return item
        })
      }
      if (
        questionData.birth &&
        isAgeValid(questionData.birth, this.minAge, this.maxAge)
      ) {
        this.currentBirth = new Date(questionData.birth)
        this.birth = questionData.birth
      } else {
        this.currentBirth = new Date('1990/01/01')
        this.birth = '1990/01/01'
      }
    }

    this.jumpQuest()
  },
  mounted() {
    let clientWidth = document.documentElement.clientWidth
    let clientHeight = document.documentElement.clientHeight
    let ratio = clientWidth / 375
    this.swipeHeight = clientHeight - (0.91 * 100 * ratio) / 2
  },
  methods: {
    checkAddress(picker, values) {
      picker.setColumnValues(1, this.cities[values[0]])
    },
    onConfirm(value) {
      this.bookingArea = value.join(' ')
      this.showPicker = false
    },
    onConfirm2(value) {
      this.bookingArea2 = value.join(' ')
      this.showPicker2 = false
    },
    onConfirm3(value) {
      this.area = value
      this.showPicker3 = false
    },
    close() {
      this.markShowNull = false
      this.markShowError = false
      this.successShow = false
    },
    onClickLeft() {
      this.markShowNull = true
    },
    backHome() {
      this.$router.back()
    },
    why() {
      this.markShowError = true
      this.errorTip = `<p>體型是健康的重要指標之一。體型數據的失衡，往往是內在核心生化指標發生重要改變的外在表現，更是慢性病發生的先兆。臨床研究指出，肥胖或過重易引發四高疾病等長期慢性疾病。</p>
							<br>
							<p>我們依據逾2500萬華人的健康大數據，通過與同健康族群者的數據比較，結合與臨牀標準的比較，可為您預測健康風險，並提出健康管理的目標與建議。</p>
							<br>
							<p>因此，請您儘可能準確測量、填寫您的體型信息。</p>`
    },
    jumpQuest() {
      setTimeout(() => {
        this.$refs.nextSwipe.swipeTo(this.currentIndex, {
          immediate: true,
        })
        this.progressNum = ((this.currentIndex + 1) / this.questionNum) * 100

        //切換效果
        this.$nextTick(() => {
          var target = document.querySelectorAll('.van-swipe-item')[
            this.currentIndex
          ]
          var prevToggle = document.querySelector('.toggle-question')
          prevToggle ? prevToggle.classList.remove('toggle-question') : ''
          target.className += ' toggle-question'
        })
      })
    },
    isGo() {
      this.currentIndex++
      this.jumpQuest()
    },

    wbc(num) {
      if (num > 150 || (num < 0 && num != '')) {
        this.tipsMark()
      }
    },
    hgb(num) {
      if (num > 25 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    hgbChange() {
      const hgb = this.examineList.filter((item) => item.ne == 'HGB')[0]
      if (hgb.ut == 'A') {
        this.hgbPla = '1~25'
        if (hgb.ve > 25 || (hgb.ve < 1 && hgb.ve != '')) {
          this.tipsMark()
        }
      } else if (hgb.ut == 'B') {
        this.hgbPla = '10~250'
        if (hgb.ve > 250 || (hgb.ve < 10 && hgb.ve != '')) {
          this.tipsMark()
        }
      }
    },

    plt(num) {
      if (num > 1800 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    fgChange() {
      const obj = this.examineList.filter((item) => item.ne == 'FG')[0]
      if (obj.ut == 'A') {
        this.fgPla = '30~700'
        if (obj.ve > 700 || (obj.ve < 30 && obj.ve != '')) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        this.fgPla = '1.5~40'
        if (obj.ve > 40 || (obj.ve < 1.5 && obj.ve != '')) {
          this.tipsMark()
        }
      }
    },
    fg(num) {
      const obj = this.examineList.filter((item) => item.ne == 'FG')[0]
      if (obj.ut == 'A') {
        if (num > 700 || (num < 30 && num != '')) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        if (num > 40 || (num < 1.5 && num != '')) {
          this.tipsMark()
        }
      }
    },
    hb1ac(num) {
      if (num > 25 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    hba1cChange() {
      const obj = this.examineList.filter((item) => item.ne == 'HBA1C')[0]

      if (obj.ut == 'A') {
        this.hba1cPla = '1~25'
        if (obj.ve > 25 || obj.ve < 1) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        this.hba1cPla = '0~10'
        if (obj.ve > 10 || obj.ve < 0) {
          this.tipsMark()
        }
      }
    },
    cholChange() {
      const obj = this.examineList.filter((item) => item.ne == 'CHOL')[0]
      if (obj.ut == 'A') {
        this.cholPla = '50~1100'
        if (obj.ve > 1100 || obj.ve < 50) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        this.cholPla = '1.2~28.5'
        if (obj.ve > 28.5 || obj.ve < 1.2) {
          this.tipsMark()
        }
      }
    },
    chol(num) {
      const obj = this.examineList.filter((item) => item.ne == 'CHOL')[0]

      if (obj.ut == 'A') {
        if (num > 1100 || (num < 50 && num != '')) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        if (num > 28.5 || (num < 1.2 && num != '')) {
          this.tipsMark()
        }
      }
    },
    ldlcChange() {
      const obj = this.examineList.filter((item) => item.ne == 'LDLC')[0]
      if (obj.ut == 'A') {
        this.ldlcPla = '1~650'
        if (obj.ve > 650 || obj.ve < 1) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        this.ldlcPla = '0~17'
        if (obj.ve > 17 || obj.ve < 0) {
          this.tipsMark()
        }
      }
    },
    ldlc(num) {
      const obj = this.examineList.filter((item) => item.ne == 'LDLC')[0]

      if (obj.ut == 'A') {
        if (num > 650 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        if (num > 17 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    hdlcChange() {
      const obj = this.examineList.filter((item) => item.ne == 'HDLC')[0]
      if (obj.ut == 'A') {
        this.hdlcPla = '1~250'
        if (obj.ve > 250 || obj.ve < 1) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        this.hdlcPla = '0~7'
        if (obj.ve > 7 || obj.ve < 0) {
          this.tipsMark()
        }
      }
    },
    hdlc(num) {
      const obj = this.examineList.filter((item) => item.ne == 'HDLC')[0]

      if (obj.ut == 'A') {
        if (num > 250 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        if (num > 7 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    tgChange() {
      const obj = this.examineList.filter((item) => item.ne == 'TG')[0]
      if (obj.ut == 'A') {
        this.tgPla = '1~9000'
        if (obj.ve > 9000 || obj.ve < 1) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        this.tgPla = '0~110'
        if (obj.ve > 110 || obj.ve < 0) {
          this.tipsMark()
        }
      }
    },
    tg(num) {
      const obj = this.examineList.filter((item) => item.ne == 'TG')[0]

      if (obj.ut == 'A') {
        if (num > 9000 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        if (num > 110 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    got(num) {
      if (num > 2000 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    gpt(num) {
      if (num > 4000 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    alp(num) {
      if (num > 6500 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },
    albChange() {
      if (this.examineList[12].ut == 'A') {
        this.albPla = '1~7'
        if (this.examineList[12].ve > 7 || this.examineList[12].ve < 1) {
          this.tipsMark()
        }
      } else if (this.examineList[12].ut == 'B') {
        this.albPla = '10~70'
        if (this.examineList[12].ve > 70 || this.examineList[12].ve < 10) {
          this.tipsMark()
        }
      }
    },
    alb(num) {
      if (this.examineList[12].ut == 'A') {
        if (num > 7 || (num < 1 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[12].ut == 'B') {
        if (num > 70 || (num < 10 && num != '')) {
          this.tipsMark()
        }
      }
    },
    creChange() {
      if (this.examineList[13].ut == 'A') {
        this.crePla = '0~40'
        if (this.examineList[13].ve > 40 || this.examineList[13].ve < 0) {
          this.tipsMark()
        }
      } else if (this.examineList[13].ut == 'B') {
        this.crePla = '0~3600'
        if (this.examineList[13].ve > 3600 || this.examineList[13].ve < 0) {
          this.tipsMark()
        }
      }
    },
    cre(num) {
      if (this.examineList[13].ut == 'A') {
        if (num > 40 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      } else if (this.examineList[13].ut == 'B') {
        if (num > 3600 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    uaChange() {
      const obj = this.examineList.filter((item) => item.ne == 'UA')[0]
      if (obj.ut == 'A') {
        this.uaPla = '0~20'
        if (obj.ve > 20 || obj.ve < 0) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        this.uaPla = '0~1200'
        if (obj.ve > 1200 || obj.ve < 0) {
          this.tipsMark()
        }
      }
    },
    ua(num) {
      const obj = this.examineList.filter((item) => item.ne == 'UA')[0]

      if (obj.ut == 'A') {
        if (num > 20 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      } else if (obj.ut == 'B') {
        if (num > 1200 || (num < 0 && num != '')) {
          this.tipsMark()
        }
      }
    },
    egfr(num) {
      if (num > 300 || (num < 1 && num != '')) {
        this.tipsMark()
      }
    },

    //股骨失去焦點提示
    handleTscoreTip() {
      if (this.tscore > 10 || this.tscore < -10 || this.tscore == '') {
        this.tipsMark()
      }
    },

    handleNext() {
      let payData = sessionStorage.getItem('payData')
        ? JSON.parse(sessionStorage.getItem('payData'))
        : ''
      this.hasBld = true
      if (payData && payData.package == 3) {
        this.toBio = true
        this.successShow = true
      } else {
        this.toBio = false
        this.isGo()
      }
    },

    nextQuest(isGo) {
      let _that = this
      if (isGo === 0) {
        if (this.sex == '') {
          this.markShowError = true
          this.errorTip = '請選擇性別'
        } else if (this.birth == '') {
          this.markShowError = true
          this.errorTip = '請選擇您的出生日期'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            sex: this.sex == '男' ? '1' : '2',
            birth: this.birth,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 1) {
        if (this.height == '' || this.height < 130 || this.height > 200) {
          this.markShowError = true
          this.errorTip = '請輸入合適身高，建議範圍：130~200'
        } else if (this.weight == '' || this.weight < 30 || this.weight > 160) {
          this.markShowError = true
          this.errorTip = '請輸入合適體重，建議範圍：30~160'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            height: this.height,
            weight: this.weight,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 2) {
        if (this.waist == '' || this.waist < 40 || this.waist > 150) {
          this.markShowError = true
          this.errorTip = '請輸入合適腰圍，建議範圍：40~150'
        } else if (this.hip == '' || this.hip < 60 || this.hip > 165) {
          this.markShowError = true
          this.errorTip = '請輸入合適臀圍，建議範圍：60~165'
        } else {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            waist: this.waist,
            hip: this.hip,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.isGo()
        }
      } else if (isGo === 3) {
        const arr = this.examineList.filter((l) => l.ve && l.ve > 0)
        if (arr.length || this.sbp || this.dbp) {
          let questionData = localStorage.getItem('questionData')
            ? JSON.parse(localStorage.getItem('questionData'))
            : {}
          let data = {
            ...questionData,
            examine: this.examineList,
            sbp: this.sbp,
            dbp: this.dbp,
          }
          localStorage.setItem('questionData', JSON.stringify(data))
          this.$nextTick(() => {
            this.handleToPay()
          })
        } else {
          this.markShowError = true
          this.errorTip = '生化信息至少填寫一項'
        }
      }
    },

    handleToPay() {
      let questionData = localStorage.getItem('questionData')
        ? JSON.parse(localStorage.getItem('questionData'))
        : ''

      let data = convertData(questionData, questionDataFour)
      data.birth = data.birth.replace(/[^\d]/g, '')
      data.code = 'M22'

      delete data.examineList

      data.examine = data.examine.map((item) => {
        if (!item.ve) {
          item.ut = 'A'
        }
        return item
      })

      postQuestionMyLink(data).then((res) => {
        this.submiting = false
        // console.log(res)
        if (res.code == 200) {
          const result = JSON.parse(Base64.decode(res.data))
          console.log('result', result)
          this.$router.replace(`/myLink/pay/${result.id}`)

          // this.$router.replace(`/loading/four/${result.id}`)
          // 清除本地存儲輸入內容
        } else {
          this.successShow = false
          Toast.fail(res.msg)
        }
      })
    },

    prevQuest() {
      this.currentIndex--
      this.jumpQuest()
    },
    tipsMark() {
      this.markShowError = true
      this.errorTip =
        '<p>您填寫的結果可能存在較大偏差，請您仔細核對該數值和所用單位是否正確並與您的報告一致。</p><br/><p>若您已經核對確認，並堅持以該結果進行提交和評估，我們可能會根據大數據算法對該結果進行一定程度上的修正和處理，以提供更合理的大數據評估結果和建議。</p>'
    },
    confirm() {
      if (new Date(this.currentTime).getDay() == '0') {
        Toast.fail('請選擇週日以外的日期!')
      } else {
        this.pickerShow = false
        let dateFormat = new Date(this.currentTime).getTime()
        this.bookTime = new Date(dateFormat)
          .toLocaleString()
          .replace(/:\d{1,2}$/, ' ')
      }
    },
    confirm2() {
      if (new Date(this.currentTime2).getDay() == '0') {
        Toast.fail('請選擇週日以外的日期!')
      } else {
        this.pickerShow2 = false
        let dateFormat = new Date(this.currentTime2).getTime()
        this.bookTime2 = new Date(dateFormat)
          .toLocaleString()
          .replace(/:\d{1,2}$/, ' ')
      }
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 15 === 0)
      }
      if (type === 'hour') {
        return options.filter((option) => option < 11 && option > 7)
      }
      return options
    },

    //性別選擇
    sexConfirm(value) {
      this.sex = value
      this.sexSel = false
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    //生日選擇
    birthConfirm(value) {
      var language = navigator.language
      if (language.toLocaleLowerCase().indexOf('zh-cn') >= 0) {
        this.birth = dayjs(value).format('YYYY/MM/DD')
      } else {
        this.birth = dayjs(value).format('YYYY/MM/DD')
      }
      this.savedBirth = dayjs(value).format('YYYY/MM/DD')

      this.birthSel = false
    },
    limitDate(year) {
      //參數：距今多少年
      let now = new Date()
      return new Date(
        now.getFullYear() -
          year +
          '/' +
          (now.getMonth() + 1) +
          '/' +
          now.getDate()
      )
    },
  },
  computed: {
    //年齡為20-85歲之間(直接綁定在組件上會卡死)
    birthMinDate() {
      return this.limitDate(this.maxAge)
    },
    birthMaxDate() {
      return this.limitDate(this.minAge)
    },
  },
  watch: {
    disease(newVal, oldVal) {
      if (newVal.length > 0) {
        let _that = this
        _that.diseaseArr = []
        this.disease.forEach(function(item, index) {
          _that.diseaseListAll.forEach(function(itemL, number) {
            if (item == itemL.kdis) {
              _that.diseaseArr.push(_that.diseaseListAll[number])
            }
          })
        })
        _that.diseaseList.forEach(function(item, index) {
          _that.diseaseArr.forEach(function(itemL, number) {
            if (item.kdis == itemL.kdis) {
              itemL.level = item.level
            }
          })
        })
      } else {
        this.diseaseList = [
          { kdis: 'K01', level: '0' },
          { kdis: 'K02', level: '0' },
          { kdis: 'K03', level: '0' },
          { kdis: 'K04', level: '0' },
          { kdis: 'K05', level: '0' },
          { kdis: 'K06', level: '0' },
          { kdis: 'K07', level: '0' },
          { kdis: 'K08', level: '0' },
          { kdis: 'K09', level: '0' },
          { kdis: 'K10', level: '0' },
          { kdis: 'K11', level: '0' },
          { kdis: 'K12', level: '0' },
          { kdis: 'K13', level: '0' },
          { kdis: 'K14', level: '0' },
          { kdis: 'K15', level: '0' },
          { kdis: 'K16', level: '0' },
          { kdis: 'K17', level: '0' },
          { kdis: 'K18', level: '0' },
          { kdis: 'K19', level: '0' },
        ]
      }
    },
  },
}
