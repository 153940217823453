<template>
  <div class="content">

    <van-nav-bar title="米因健康-問卷信息" left-arrow @click-left="onClickLeft" :fixed="true" />
    <van-progress color="#6681FA" :percentage="progressNum" :show-pivot="false" pivot-text="" stroke-width="4"
      track-color="#DCDEE5" />
    <div class="page_box">
      <span>{{ currentIndex + 1 }}</span>/4
    </div>
    <van-swipe :loop="false" :show-indicators="true" :touchable="false" ref="nextSwipe" :initial-swipe="currentIndex"
      :height="swipeHeight">
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你好，請告訴我你的性別和出生日期？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="sex group">
                <span>性別</span>
                <div class="groupLabel">
                  <div class="arrow"></div>
                  <van-field readonly clickable :value="sex" placeholder="請選擇" @click="isMuying ? '' : sexSel = true" />
                </div>
              </div>
              <div class="group">
                <span>出生日期</span>
                <div class="groupLabel">
                  <div class="arrow"></div>
                  <van-field readonly clickable :value="birth" placeholder="請選擇" @click="birthSel = true" />
                </div>
              </div>
            </div>
            <div class="nextQues" @click="nextQuest(0)">下一步</div>
            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                該健康AI評估僅適用於年齡在20-85週歲之間的人群
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                你好，請告訴我你的身高、體重？
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <span>身高</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="height" placeholder="請填寫" type="number" />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>體重</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="weight" placeholder="請填寫" type="number" />
                    <div class="unit"><span>kg</span></div>
                  </label>
                </div>
              </div>

            </div>

            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues" @click="nextQuest(1)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                體脂率不清楚的可選擇不填哦！ 大數據引擎會根據你的情況幫你推算。 除非特殊體型，比如常做健身的肌肉比例比普通人高很多，普通人的體脂率可以通過大數據估算出來。
                <br>若你屬於健身愛好者，運動員等體脂率較為特殊的人群，建議你前往合資格體檢或健身中心測量你的體脂率，再做一次健康AI評估。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請告訴我你的腰臀圍信息？請你儘可能準確測量、填寫你的體型信息。 <span @click="why" class="qjc-c-primary">為什麼？</span>
                <span @click="stand = true" class="qjc-c-primary">測量標準</span>
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <div class="group_title_cell">
                  <span>腰圍</span>
                  <span class="cankao" @click="showPopover = true">參考腰圍</span>
                </div>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="waist" placeholder="請填寫" type="number" />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <div class="group_title_cell">
                  <span>臀圍</span>
                  <span class="cankao" @click="showPopoverHip = true">參考臀圍</span>
                </div>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="hip" placeholder="請填寫" type="number" />
                    <div class="unit"><span>cm</span></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="nextQues waist_nextQuest" @click="nextQuest(2)">下一步</div>

            <div class="question1 new_question">
              <div class="avatar"></div>
              <div class="quesText">
                <img class="question_tips_icon" src="@/assets/images/yixin/question_tips.png" alt="">
                隨年齡增長，BMI與四高、長期慢病及重症，有非常緊密的聯繫。而四高風險對個人免疫力有非常大的影響，所以，要降低四高風險，首先應加強管理體型、體重。
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="swipeBox">
          <div class="swiperScroll">
            <div class="question">
              <div class="avatar"></div>
              <div class="quesText">
                請填寫你的生化信息
                <div class="red-tip"><sup>*</sup>以下生化信息中至少填寫一項</div>
              </div>
              <div class="clear"></div>
            </div>
            <div class="reply">
              <div class="group">
                <span>收縮壓</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="sbp" placeholder="請輸入" type="number" />
                    <div class="unit" style="line-height: 0.65rem">
                      <span>mmHg</span>
                    </div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>舒張壓</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="dbp" placeholder="請輸入" type="number" />
                    <div class="unit"><span>mmHg</span></div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>空腹血糖(FG)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="examineList[0].ve" placeholder="請輸入" @blur="fg(examineList[0].ve)"
                      type="number" />
                    <div class="unit">
                      <label style="margin-right: 0.32rem;"> <input v-model="examineList[0].ut" value="B" type="radio"
                          name="fg" @change="fgChange">
                        <p></p> <span>mmol/L</span>
                      </label>
                      <label> <input v-model="examineList[0].ut" value="A" type="radio" name="fg" @change="hgbChange">
                        <p></p> <span>mg/dL</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>糖化血紅蛋白(HbA1c)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="examineList[1].ve" placeholder="請輸入" @blur="hb1ac(examineList[1].ve)"
                      type="number" />
                    <div class="unit">
                      <label style="margin-right: 0.32rem;"> <input v-model="examineList[1].ut" value="B" type="radio"
                          name="hgb" @change="hba1cChange">
                        <p></p> <span>mm0l/L</span>
                      </label>
                      <label> <input v-model="examineList[1].ut" value="A" type="radio" name="hgb"
                          @change="hba1cChange">
                        <p></p> <span>%</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>總膽固醇(CHOL)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="examineList[2].ve" placeholder="請輸入" @blur="chol(examineList[2].ve)"
                      type="number" />
                    <div class="unit">
                      <label style="margin-right: 0.32rem;"> <input v-model="examineList[2].ut" value="B" type="radio"
                          name="chol" @change="cholChange">
                        <p></p><span>mmol/L</span>
                      </label>
                      <label> <input v-model="examineList[2].ut" value="A" type="radio" name="chol"
                          @change="cholChange">
                        <p></p><span>mg/dL</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>低密度脂蛋白膽固醇(LDLC)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="examineList[3].ve" placeholder="請輸入" @blur="ldlc(examineList[3].ve)"
                      type="number" />
                    <div class="unit">
                      <label style="margin-right: 0.32rem;"> <input v-model="examineList[3].ut" value="B" type="radio"
                          name="ldlc" @change="ldlcChange">
                        <p></p><span>mmol/L</span>
                      </label>
                      <label> <input v-model="examineList[3].ut" value="A" type="radio" name="ldlc"
                          @change="ldlcChange">
                        <p></p><span>mg/dL</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>高密度脂蛋白膽固醇(HDLC)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="examineList[4].ve" placeholder="請輸入" @blur="hdlc(examineList[4].ve)"
                      type="number" />
                    <div class="unit">
                      <label style="margin-right: 0.32rem;"> <input v-model="examineList[4].ut" value="B" type="radio"
                          name="hdlc" @change="hdlcChange">
                        <p></p><span>mmol/L</span>
                      </label>
                      <label> <input v-model="examineList[4].ut" value="A" type="radio" name="hdlc"
                          @change="hdlcChange">
                        <p></p><span>mg/dL</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>甘油三酯(TG)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="examineList[5].ve" placeholder="請輸入" @blur="tg(examineList[5].ve)"
                      type="number" />
                    <div class="unit">
                      <label style="margin-right: 0.32rem;"> <input v-model="examineList[5].ut" value="B" type="radio"
                          name="tg" @change="tgChange">
                        <p></p><span>mmol/L</span>
                      </label>
                      <label> <input v-model="examineList[5].ut" value="A" type="radio" name="tg" @change="tgChange">
                        <p></p><span>mg/dL</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>
              <div class="group">
                <span>尿酸(UA)</span>
                <div class="groupLabel">
                  <label>
                    <van-field v-model="examineList[6].ve" placeholder="請輸入" @blur="ua(examineList[6].ve)"
                      type="number" />
                    <div class="unit">
                      <label style="margin-right: 0.32rem;"> <input v-model="examineList[6].ut" value="A" type="radio"
                          name="ua" @change="uaChange">
                        <p></p><span>mg/dL</span>
                      </label>
                      <label> <input v-model="examineList[6].ut" value="B" type="radio" name="ua" @change="uaChange">
                        <p></p><span>μmol/L</span>
                      </label>
                    </div>
                  </label>
                </div>
              </div>

            </div>
            <div class="prevQues" @click="prevQuest">上一步</div>
            <div class="no-bio-pre nextQues" @click="nextQuest(3)">選好了，提交</div>
          </div>
        </div>
      </van-swipe-item>

    </van-swipe>
    <div class="mark" v-show="markShowNull">
      <div>
        <div class="markBox">
          <img src="@/assets/images/errorTip.png" alt="">
          <div class="tipText">
            你尚有未完成的健康AI評估，是否繼續完成？
          </div>
          <div class="btnGroup">
            <div class="btnEsc" @click="backHome">返回首頁</div>
            <div class="btnEnt" @click="close">繼續完成</div>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>
    <div class="mark" v-show="markShowError">
      <div>
        <div class="markBoxError">
          <div class="markBoxErrorImg">
            <img src="@/assets/images/tips.png" alt="">
          </div>
          <div class="tipText2 tipText3" v-html="errorTip">

          </div>
          <div class="btnGroup">
            <div class="btnEnt2 qjc-fts-32" @click="close">我知道了</div>
          </div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>

    <div class="mark user_name" v-show="successShow">
      <div>
        <div class="markBox">
          <img class="success_icon" src="@/assets/images/success_icon.png" alt="">
          <div class="tipText success_text">
            問卷信息已核實，確認提交問卷信息。<br>問卷信息會自動保存(最多可創建10個)，你可以在下次填寫時調取。<br><span
              v-if="!hasBld || isMuying">請在下方填寫該健康AI評估信息的用户名與描述，方便你進行記憶與管理。</span>
          </div>
          <div class="name_wrap" v-if="!hasBld || isMuying">
            <van-field class="name_input" v-model="username" placeholder="用户名" type="text" />
          </div>
          <div class="btnGroup success_pop">
            <div class="btnEnt" @click="handleToPay">確認</div>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="close" @click="close"></div>
      </div>
    </div>

    <!-- 性別選擇 生日選擇 -->
    <van-popup v-model="sexSel" position="bottom">
      <van-picker :show-toolbar="true" :columns="sexList" @cancel="sexSel = false" @confirm="sexConfirm"
        class="sexPicker" />
    </van-popup>
    <van-popup v-model="birthSel" position="bottom">
      <van-datetime-picker type="date" :formatter="formatter" v-model="currentBirth" :min-date="birthMinDate"
        :max-date="birthMaxDate" @cancel="birthSel = false" @confirm="birthConfirm" class="birthPicker" />
    </van-popup>

    <van-popup v-model="pickerShow" position="bottom">
      <div class="dateName">
        <ul>
          <li>年</li>
          <li>月</li>
          <li>日</li>
          <li>時</li>
          <li>分</li>
        </ul>
      </div>
      <van-datetime-picker v-model="currentTime" type="datetime" :filter="filter" :min-date="minDate"
        :max-date="maxDate" confirm-button-text="確定" cancel-button-text="取消" @cancel="pickerShow = false"
        @confirm="confirm" />
    </van-popup>
    <van-popup v-model="pickerShow2" position="bottom">
      <div class="dateName">
        <ul>
          <li>年</li>
          <li>月</li>
          <li>日</li>
          <li>時</li>
          <li>分</li>
        </ul>
      </div>
      <van-datetime-picker v-model="currentTime2" type="datetime" :filter="filter" :min-date="minDate"
        :max-date="maxDate" confirm-button-text="確定" cancel-button-text="取消" @cancel="pickerShow2 = false"
        @confirm="confirm2" />
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @change="checkAddress" @cancel="showPicker = false"
        confirm-button-text="確定" cancel-button-text="取消" @confirm="onConfirm" />
    </van-popup>
    <van-popup v-model="showPicker2" position="bottom">
      <van-picker show-toolbar :columns="columns" @change="checkAddress" @cancel="showPicker2 = false"
        confirm-button-text="確定" cancel-button-text="取消" @confirm="onConfirm2" />
    </van-popup>
    <van-popup v-model="showPicker3" position="bottom">
      <van-picker show-toolbar :columns="areaList" @cancel="showPicker3 = false" confirm-button-text="確定"
        cancel-button-text="取消" @confirm="onConfirm3" />
    </van-popup>
    <!-- 測量標準 -->
    <van-popup v-model="stand" class="stand">
      <van-image width="4.36rem" height="4.37rem"
        :src="require('@/assets/images/questions_measureStand.png')"></van-image>
      <ul class="qjc-c-dark qjc-fts-28 qjc-texta-l">
        <li>
          胸圍：平靜呼吸時，經乳頭點的胸部水平圍長。
        </li>
        <li>
          腰圍：經臍部中心的水平圍長，或肋骨以下、胯骨以上的最小水平圍長，在呼氣結束、吸氣未開始時測量。
        </li>
        <li>
          臀圍：臀部向後最突出部位的水平圍長。
        </li>
      </ul>
      <!-- 關閉按鈕 -->
      <van-image width="0.8rem" height="0.8rem" class="close-btn" @click="stand = false"
        :src="require('@/assets/images/givingMasks_close.png')"></van-image>
    </van-popup>
    <van-popup class="wait_popup" v-model="showPopover">
      <div class="size_chart" v-if="sex == '男'">
        <div class="size_chart_title">男士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">70</div>
              <div class="tab_cell_item">74</div>
              <div class="tab_cell_item">78</div>
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">88</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">36</div>
              <div class="tab_cell_item no_bottom">38</div>
              <div class="tab_cell_item no_bottom">40</div>
              <div class="tab_cell_item no_bottom">42</div>
              <div class="tab_cell_item no_bottom">44</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">98</div>
              <div class="tab_cell_item">100</div>
              <div class="tab_cell_item">104</div>
              <div class="tab_cell_item">106</div>
            </div>
          </div>
        </div>
      </div>
      <div class="size_chart" v-if="sex == '女'">
        <div class="size_chart_title">女士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">25</div>
              <div class="tab_cell_item no_bottom">26</div>
              <div class="tab_cell_item no_bottom">27</div>
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">60</div>
              <div class="tab_cell_item">64</div>
              <div class="tab_cell_item">68</div>
              <div class="tab_cell_item">70</div>
              <div class="tab_cell_item">74</div>
              <div class="tab_cell_item">77</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">35</div>
              <div class="tab_cell_item no_bottom">36</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">腰圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">87</div>
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">97</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup class="wait_popup" v-model="showPopoverHip">
      <div class="size_chart" v-if="sex == '男'">
        <div class="size_chart_title">男士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">99</div>
              <div class="tab_cell_item">101</div>
              <div class="tab_cell_item">103</div>
              <div class="tab_cell_item">105</div>
              <div class="tab_cell_item">107</div>
              <div class="tab_cell_item">109</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">36</div>
              <div class="tab_cell_item no_bottom">38</div>
              <div class="tab_cell_item no_bottom">40</div>
              <div class="tab_cell_item no_bottom">42</div>
              <div class="tab_cell_item no_bottom">44</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">111</div>
              <div class="tab_cell_item">113</div>
              <div class="tab_cell_item">116</div>
              <div class="tab_cell_item">119</div>
              <div class="tab_cell_item">121</div>
              <div class="tab_cell_item">125</div>
            </div>
          </div>
        </div>
      </div>
      <div class="size_chart" v-if="sex == '女'">
        <div class="size_chart_title">女士尺碼錶</div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">25</div>
              <div class="tab_cell_item no_bottom">26</div>
              <div class="tab_cell_item no_bottom">27</div>
              <div class="tab_cell_item no_bottom">28</div>
              <div class="tab_cell_item no_bottom">29</div>
              <div class="tab_cell_item no_bottom">30</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">80</div>
              <div class="tab_cell_item">84</div>
              <div class="tab_cell_item">87</div>
              <div class="tab_cell_item">90</div>
              <div class="tab_cell_item">94</div>
              <div class="tab_cell_item">97</div>
            </div>
          </div>
        </div>
        <div class="size_chart_tab">
          <div class="size_chart_tab_cell">
            <div class="the no_bottom">尺碼(英寸)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item no_bottom">31</div>
              <div class="tab_cell_item no_bottom">32</div>
              <div class="tab_cell_item no_bottom">33</div>
              <div class="tab_cell_item no_bottom">34</div>
              <div class="tab_cell_item no_bottom">35</div>
              <div class="tab_cell_item no_bottom">36</div>
            </div>
          </div>
          <div class="size_chart_tab_cell">
            <div class="the">臀圍(cm)</div>
            <div class="tab_cell_item_list">
              <div class="tab_cell_item">103</div>
              <div class="tab_cell_item">107</div>
              <div class="tab_cell_item">110</div>
              <div class="tab_cell_item">113</div>
              <div class="tab_cell_item">120</div>
              <div class="tab_cell_item">127</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>


    <!-- <van-number-keyboard v-model="qntimes" :show="keyboardShow" @blur="keyboardShow = false" /> -->
  </div>
</template>

<script>
import questionBj from './four';
import Vue from 'vue';
import {
  NavBar,
  Progress,
  Swipe,
  SwipeItem,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Field,
  Picker,
  Popup,
  Area,
  DatetimePicker,
  Cell,
  CellGroup,
  Loading,
  NumberKeyboard
} from 'vant';

Vue.use(NavBar).use(Progress).use(Swipe).use(SwipeItem).use(RadioGroup).use(Radio).use(Checkbox).use(CheckboxGroup)
  .use(Field).use(Picker).use(Popup).use(Area).use(DatetimePicker).use(Cell).use(CellGroup).use(Loading).use(
    NumberKeyboard);
export default {
  ...questionBj
}
</script>

<style src="@/assets/css/quesstion.css" scoped></style>
<style scoped>
.reply {
  height: fit-content;
}

.gz-times-cell {
  position: relative;
}

.gz-times {
  position: absolute;
  top: 0.28rem;
  left: 1.2rem;
  display: flex;
  align-items: center;
  width: 5rem !important;
}

.gz-times .unit {
  color: #333333;
  margin-left: 0.04rem;
}

.gz-times-cell>>>input {
  text-align: right;
}

.content>>>.van-icon-arrow-left:before {
  color: #40444D;
}

.content>>>.van-nav-bar__title {
  font-size: 0.36rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.content>>>.van-progress {
  margin-top: 46px;
}

.content>>>.van-radio {
  padding: 0.4rem 0;
  border-bottom: 0.01rem solid #EEEEEE;
  text-align: left;
}

.content>>>.van-radio:last-child {
  border: none;
}

.content>>>.van-checkbox {
  padding: 0.4rem 0;
  border-bottom: 0.01rem solid #EEEEEE;
}

.content>>>.van-checkbox__icon .van-icon {
  border-radius: 4px;
}

.content>>>.van-swipe__indicators {
  bottom: -12px;
}

.content>>>.van-cell {
  padding: 0;
  float: left;
  width: 100%;
  height: 0.7rem;
}

.content>>>.van-cell input {
  float: left;
  font-size: 0.32rem;
  height: 0.7rem;
  line-height: 0.7rem;
}

.content>>>.van-cell input::placeholder {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 300;
  color: rgba(161, 167, 178, 1);
}

.selArea>>>.van-cell {
  width: 100% !important;
}

.success_pop {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem !important;
}

.success_icon {
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.name_wrap {
  width: 100%;
  height: 0.84rem;
  padding: 0 0.4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.name_input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.84rem !important;
  line-height: 0.84rem !important;
  padding: 0 0.3rem !important;
  border: 0.01rem solid #DCDFE6;
  border-radius: 0.08rem;
}

.success_text {
  height: auto;
}

.no-bio {
  position: relative;
  display: inline-block;
  margin: 0 0.08rem 0.8rem;
  width: 3.2rem;
  height: 0.88rem;
  border: 0.02rem solid rgba(102, 129, 250, 1);
  border-radius: 0.44rem;
  /* font-size:0.34rem;
		font-family:PingFang SC; */
  /* font-weight:500; */
  color: rgba(102, 129, 250, 1);
  line-height: 0.82rem;
  text-align: center;
  background-image: none;
}

.no_bio_cell {
  line-height: 0.58rem !important;
}

.yanx {
  font-size: 0.2rem;
  position: absolute;
  left: 0.1rem;
  bottom: 0.14rem;
  line-height: 0.2rem;
}

.new_question {
  margin: 0rem 0.24rem 1.48rem 0.24rem;
}

.new_question .quesText {
  font-size: 0.28rem;
  line-height: 0.48rem;
  min-width: 5.7rem !important;
}

.new_question .question_tips_icon {
  width: 1.06rem;
  height: 0.32rem;
}

.waist_nextQuest {
  margin-bottom: 0.5rem !important;
}

.sleep_nextQues {
  margin-bottom: 0.5rem !important;
}

.qjc-nav-bar>>>.van-nav-bar__content {
  height: 0.88rem;
}

.prevQues,
.nextQues {
  display: inline-block;
  margin: 0 0.08rem 0.5rem;
  width: 3.2rem;
  height: 0.88rem;
  border: 0.02rem solid rgba(102, 129, 250, 1);
  border-radius: 0.44rem;
  font-size: 0.34rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(102, 129, 250, 1);
  line-height: 0.88rem;
  text-align: center;
  background-image: none;
}
</style>

<style lang="scss" scoped>
.group_title_cell {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cankao {
    color: rgba(140, 145, 154, 1);
    font-family: PingFang SC;
    font-size: 0.28rem;
  }
}

.wait_popup {
  border-radius: 0.12rem;
}

.size_chart {
  width: 6rem;
  height: 3.6rem;
  text-align: center;
  padding: 0.3rem;
  border-radius: 0.12rem;

  .size_chart_title {
    font-size: 0.28rem;
    font-weight: 500;
    font-family: SF Pro Text;
    color: rgb(50, 54, 62);
  }

  .size_chart_tab {
    margin-top: 0.16rem;
  }

  .size_chart_tab_cell {
    display: flex;
    align-items: center;

    .tab_cell_item_list {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .tab_cell_item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.6rem;
      height: 0.48rem;
      // padding: 0 0.10rem;
      border-top: 0.01rem solid rgb(215, 219, 225);
      border-bottom: 0.01rem solid rgb(215, 219, 225);
      border-right: 0.01rem solid rgb(215, 219, 225);
      box-sizing: border-box;
    }

    .the {
      display: flex;
      align-items: center;
      width: 1.6rem;
      text-align: left;
      height: 0.48rem;
      padding: 0 0.10rem;
      border: 0.01rem solid rgb(215, 219, 225);
    }

    .no_bottom {
      border-bottom: none !important;
    }
  }
}

.markBox {
  height: auto;
  padding-bottom: 0.4rem;
}

.user_name .success_text {
  width: 100%;
  padding: 0 0.4rem;
  text-align: left;
}

//測量標準
.van-popup {
  &.stand {
    width: 5.88rem;
    height: 8.89rem;
    padding-top: 0.72rem;
    border-radius: 0.04rem;
    overflow: visible;

    ul {
      margin-top: 0.48rem;

      li {
        position: relative;
        line-height: 0.4rem;
        margin: 0 0.26rem 0.26rem 0.58rem;

        &::before {
          position: absolute;
          top: 0.05rem;
          left: -0.37rem;
          width: 0.28rem;
          height: 0.28rem;
          line-height: 0.28rem;
          border: 0.01rem solid #40444D;
          border-radius: 50%;
          text-align: center;
          font-size: 0.2rem;
        }

        &:first-child::before {
          content: '1';
        }

        &:nth-child(2)::before {
          content: '2';
        }

        &:nth-child(3)::before {
          content: '3';
        }
      }
    }

    .close-btn {
      position: absolute;
      left: 50%;
      bottom: -1.19rem;
      transform: translateX(-50%);
    }
  }
}

.red-tip {
  color: #ff2110;
}

.page_box {
  position: absolute;
  top: 56px;
  right: 0.18rem;
  width: 1.2rem;
  font-size: 0.3rem;
  padding: 0.08rem 0.12rem;
  border: 0.01rem solid rgba(102, 129, 250, 1);
  border-radius: 0.4rem;
  z-index: 999;
  font-weight: 500;

  span {
    font-size: 0.36rem;
    color: rgba(102, 129, 250, 1);
  }

}
</style>